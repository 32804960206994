import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Another day of failure. Why can’t a young teenager like me reach her goals?! Maybe dance isn’t the right thing for me. All that I hear is ‘Lin quit, it’s not your thing. You’re failing it I understand that my mom wants what’s best for me, but to hear that from your dance teacher, it’s awful.`}</p>
    <p>{`Long ago, maybe like 3 years ago, I’ve found interest in dancing. It went from interest to passion. For some reason, I‘ve been dancing for so long but never succeeded. My dance teacher never takes me to dance battels, because I am not ready. The only person that might support me is Emma. Emma has been dancing since she was 3. No wonder she is so good. She keeps telling me not to quit, but I don’t know if she just says that because she is my friend.`}</p>
    <p>{`I really don’t know what to do. From one point of view, it seems to be my passion and I enjoy it, but no one really sees me, I feel like I am invisible. Maybe I should just quit really, just like my mom and the dance teacher say. When I think about the fact of quitting doing it breaks my heart. I really don’t want to forget and quit my passion. I think the only good option now is to go home to get a nice shower and go to sleep. Tomorrow is a new day and I will think of what to do next. Still, I am not going to school tomorrow, therefore I will have time to think.`}</p>
    <p>{`I went out of the changing room and headed to get my coat out of the closet. As soon as I was ready I took my phone and earbuds out of my pocket, I placed the earbuds in my ears and connected them to my phone. Maybe some cheerful music will bring some positive thoughts to me.`}</p>
    <p>{`As I walked out of the building, I felt the small snowflakes hit my warm face, causing them to melt. The cold breeze made me shrug. As I walked past the beautiful busy city with snow falling at night it made me think. The city is always busy, it never stops working, and the results are fantastic. That sounds like me, except for the last part. There are NO results! I felt like the tears filled up my eyes. I took out a warm tissue with my cold hands and wiped off the tears, that would soon turn into ice. Why can’t I be just like this city? The walk back to my house wasn’t filled with happy thoughts, instead, my head was filled with cold thoughts that made me almost cry. All of them being about my passion, dance. No music could help here.`}<br parentName="p"></br>{`
`}{`Before I entered my house, I wiped the rest of my tears, so my mom wouldn’t be worried. “Hey, mom!” I said with a fake smile on my face. “Oh, Lin! Your back, come and have dinner” I heard my mom’s voice coming from the kitchen. I wasn’t really hungry, I just wanted to go to sleep, “Um mom I will just go to sleep, I m very tired after today’s training” I answered and ran upstairs.`}</p>
    <p>{`The warm shower made me forget about the problems, but just for 10 minutes. As I walked out of the shower I fell onto my bed and stared at the ceiling until I got a message from Emma. I reached out for my phone that was on the night shelf and checked the message. “Hey, Lin. I know you might be upset about your dancing, but I just wanted to let you know that we have a battle in 1 week. Just so you know” I answered with thanks and turned my phone off, and placed it back onto the shelf. But really it’s my only chance to show that my passion for dancing is strong, and I can do it.`}</p>
    <p>{`My dance teacher doesn’t have faith in me. All I can do is to practice at home hours and hours. I only have 7 days, well 6. I have to be ready on Sunday. But now I will let myself into the world of dreams and sleep.`}</p>
    <p>{`The sunlight that hit my face woke me up, of course, I was so tired yesterday that I forgot to close the blinds, perfect. I rose from my bed, ready for the new busy day. My mom went to work, of course, its Monday. I have the whole day to practice. I made myself some fried eggs and got dressed in my gym clothes. I played some rap music and started with a warm-up. I tried to recall all the dance moves I learned from my dance teacher. One by one and I have mastered already 3! That’s some progress in an hour. I took a 10-minute break and continued. Now I need to master 7 more moves.`}</p>
    <p>{`After 5 more hours, I have mastered 4 moves. I was proud of myself. Now I will get myself ready or the next days of hard work.`}</p>
    <p>{`The 6 days went by pretty fast. Each day I got 3 or sometimes 4 moves mastered. Now I have a full set of 10 moves. I feel like that will be enough. Today is the last day for me to practice, I am already signed up for the battle, and no teacher this time told me I can’t attend it.`}</p>
    <p>{`I am confident in myself as well as in my dance. However, I would like to practice today as well. Today I did the finishing touches and got myself a nice grey hoodie and black leggings. This day went by faster than I expected it to go by. I couldn’t sleep this night, probably because of the stress.`}</p>
    <p>{`I woke up from the bad night’s sleep and ate breakfast. I tried to eat more nutrients today. After the nutritious breakfast, my mom made me, I got dressed in my grey hoodie and black leggings I got myself ready the day before. Finally, I had to get into the car with my mom and drive to the battle.`}</p>
    <p>{`When I arrived I was shocked by how big the stage is, and how small I am compared to it. I felt like an ant while looking at the stage.`}</p>
    <p>{`I didn’t get time to prepare, as soon as I entered the building I heard my name. As soon as I heard it my heart started to beat as fast as a cheetah running. I took a deep breath and headed towards the stage.`}<br parentName="p"></br>{`
`}{`As I entered the stage, the bright lights made me go blind. I couldn’t see the judges, as well as the audience. I closed my eyes and waited for the music to start.`}</p>
    <p>{`As soon as I heard the first beats of the song, I started to dance with all my force. I felt all my muscles working together. I totally forgot about the world around me. I just heard the music and felt me dancing with all my force.`}</p>
    <p>{`The music stopped and I could take a deep breath in. I heard the applause coming from the audience. I went down the stage and waited for the other dancers to finish dancing.`}</p>
    <p>{`Finally, the moment of truth. I heard my name again. I felt my heartbeat as fast as a cheetah running. The next words that came from one of the judges made me so happy that tears came running down my face. “Lin, 1st place”.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      